import React, { useState } from "react";
import User from "../../../../assests/images/user.png";
import {
  StyledHeader,
  StyledHeaderRight,
  StyledLogo,
  StyledAdminHeaderRight,
} from "./style";
import { FaSignOutAlt } from "react-icons/fa";
import { FaHome } from "react-icons/fa";
import { logout } from "../../../../redux/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleSidebar } from "../../../../redux/orderSlice";
import Dropdown from "react-bootstrap/Dropdown";
import jwt from "jwt-decode";
import AdminLogo from "../../../../assests/img/edocs-logo.png";
import Modal from "../../../../Components/Modal/Modal";
import PasswordModal from "../../../../pages/Admin/PasswordModal/PasswordModal"
import newPasswordIcon from "../../../../assests/images/icon10.svg"
import manageTeamIcon from "../../../../assests/images/icon7.svg"

const AdminNavbar = () => {
  const [showButton, setShowButton] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("user");
  const handleLogout = (e) => {
    e.preventDefault();
    localStorage.removeItem("user");
    sessionStorage.removeItem("loginUser")
    dispatch(logout());
  };

  const handleShow = (orderId) => {
    setShowButton(true);
  };

  const handleTeamMemberList = (e) => {
    e.preventDefault();
    navigate('/manage-team');
  };
  const handleLandingList = (e) => {
    e.preventDefault();
    navigate('/landinglist');
  };
  const handleHomePage = (e) => {
    e.preventDefault();
    navigate('/');
  };
  let userDetails;

  if (token !== null) {
    const decodedToken = jwt(token);
    userDetails = decodedToken?.user;
  }



  return (
    <StyledHeader>
      <div className="toggle-button-box">
        <button
          type="button"
          className="toggle-button"
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
      <StyledLogo>
        <div className="d-flex heading-section">
        <img src={AdminLogo} alt="Logo" />
        <h4>Button Console</h4>

        </div>
      </StyledLogo>
      <StyledHeaderRight>
        <StyledAdminHeaderRight>
          <ul>
            <li className="dropdown">
              <span className="admin-name">{`Welcome ${userDetails?.name}`}</span>
              <div className="user-top">
                <span className="online"></span>
                <span className="user-img">
                  <img src={User} alt="user" />
                </span>
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="dropdown-toggle"
                >
                  {" "}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={handleLogout}>
                    {" "}
                    <FaSignOutAlt className="logout-icons" />
                    Logout
                  </Dropdown.Item>
               
                    <>
                      <Dropdown.Item href="#" onClick={handleLandingList}>
                        {" "}
                        <FaHome className="logout-icons" />
                        Landing Buttons
                      </Dropdown.Item>
                    </>
               

                  <Dropdown.Item href="#" onClick={handleShow}>
                    {" "}
                    <img src={newPasswordIcon} className="logout-icons" />
                    Change Password
                  </Dropdown.Item>

                  {typeof userDetails !== "undefined" && (userDetails?.type == 'Admin' || userDetails?.type == 'Manager') && (
                    <>
                      <Dropdown.Item href="#" onClick={handleTeamMemberList}>
                        {" "}
                        <img src={manageTeamIcon} className="logout-icons" />
                        Manage Team
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
          <Modal
            show={showButton}
            close={setShowButton}
            size={"xl"}
            modalHeadTitle={"Please Enter New Password"}
            showFooter={false}
          >
            <PasswordModal setShowButton={setShowButton} userDetails={userDetails} />

          </Modal>
        </StyledAdminHeaderRight>
      </StyledHeaderRight>
    </StyledHeader>
  );
};

export default AdminNavbar;
