import React from 'react'
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { StyledPasswordModal } from "./style"
import { updateNewPassword } from "../../../redux/orderSlice";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { logout } from "../../../redux/authSlice";

const PasswordModal = ({ setShowButton, userDetails }) => {
  const encryptString = (text, secretKey) => {
    return CryptoJS.AES.encrypt(text, secretKey).toString();
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const initialValues = {
    password: ''
  };
  const validationSchema = yup.object({
    password: yup.string().min(6).required("Please enter new password"),
  });
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        newPassword: values?.password,
        id: userDetails?.id ? userDetails?.id : "",
      };
      dispatch(updateNewPassword(data))
        .unwrap()
        .then((res) => {
          if (res.status === 1) {
            toast.success(`${res?.message}`, {
              className: "toast-message",
            });
            setShowButton(false);
            localStorage.removeItem("user");
            sessionStorage.removeItem("loginUser")
            dispatch(logout());
            navigate('/');
          } else {
            toast.error(`${res?.message}`, {
              className: "toast-message",
            });
          }
          // setShowButton(false);
        });
    },
  });
  return (
    <StyledPasswordModal>
      <form onSubmit={handleSubmit}>
        <div className="card-body col-lg-6 password-section">
          <div className="form-group mt-3">
            <div className="form-label-group">
              <label htmlFor="inputPassword">New Password</label>
              <input
                type="password"
                id="inputPassword"
                name="password"
                className="form-control"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p style={{ color: "red" }}>{errors.password}</p>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-block mt-4 w-100 submit-btn"
          >
            Login
          </button>
        </div>
      </form>
    </StyledPasswordModal>
  )
}

export default PasswordModal