import styled from "styled-components";

export const DomainCard = styled.div`
padding: 1%;
.card{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    cursor: pointer;
}

& .card-img-top {
    height: 200px;
}
& .card-link {
    text-decoration: none;
}
`;