import React from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../../../pages/PageNotFound";
import Login from "../../../pages/AdminLogin/Login";
import TeamMemberList from "../../../pages/Admin/TeamMemberList";
import LandingList from "../../../pages/Admin/LandingList";
import SingleDomainList from "../../../pages/Admin/SingleDomainList";

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/manage-team" element={<TeamMemberList />} />
        <Route path="/manage-team/:teamMemberId" element={<TeamMemberList />} />
        <Route path="/landinglist" element={<LandingList />} />
        <Route path="/domain-list/:id" element={<SingleDomainList />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </main>
  );
};

export default Main;
