import styled from "styled-components";

export const TransactionContainer = styled.div`
  & label {
    font-weight: 600;
  }

  .form-group {
    padding: 10px 18px;
    display: flex;
    justify-content: space-evenly;
  }

  .inline-image {
    height: 247px;
    width: 268px;
    display: inline-block;
    // margin-right: 10px; /* Adjust margin as needed */
  }

  // .image-button1{
  //   float: left;
  //   margin-left: 15px;
  // }

  // .image-button2{
  //   margin-right: -60px;
  // }

  // .image-none{
  //   float: right;
  //   margin-right: 55px;
  // }

  .submit-admin-form {
    display: block;
    margin: 0 auto;
    width: 30%;
  }

  .thing-section img {
    width: 100%;
  }

  .radio-buttons {
    display: flex;
    justify-content: center;
    // text-align: center; /* Optional: Center-align the radio buttons */
  }

  .form-control {
    background-color: transparent;
    border: 1px solid #e3e3e3;
    border-radius: 30px;
    color: #2c2c2c;
    line-height: normal;
    font-size: 0.93em;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: auto;
  }

  .form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
  }

  & p {
    margin-top: 0;
    margin-bottom: 1rem;
    color: red;
  }

  & button {
    color: #fff;
    background-color: #a91010;
    border-color: #2e6da4;
    padding: 8px;
    border-radius: 4px;
    color: #fff;
    border: none;
  }
`;
