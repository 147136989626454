import axios from "axios";

const defaultConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  contentType: "application/json",
};

export default class ApiService {
  static getConfigs = (additionalConfig = {}) => {
    const config = {
      ...defaultConfig,
      ...additionalConfig,
    };

    const user = JSON.parse(localStorage.getItem("user"));

    if (user && user.accessToken !== null) {
      config.headers = { ...config.headers, Authorization: user.accessToken };
    }
    return config;
  };

  static get = async (url, params) => axios.get(url, this.getConfigs(params));

  static post = async function post(url, data, additionalConfig = {}) {
    return axios.post(url, data, this.getConfigs(additionalConfig));
  };

  static put = async (url, data) => axios.put(url, data, this.getConfigs());
  static patch = async (url, data) => axios.patch(url, data, this.getConfigs());
}
