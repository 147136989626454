import React from "react";
import { TransactionContainer } from "./style";
import { useFormik } from "formik";
import { updateSiteButton, getAllDomainList } from "../../../redux/orderSlice";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const ButtonModal = ({ setShowButton, orderDetails, id }) => {
  const dispatch = useDispatch();
  const initialValues = {
    buttonSelection: orderDetails[0]?.button1 == "Active" ? '1' : orderDetails[0]?.button2 == "Active" ? '2' : orderDetails[0]?.button3 == "Active" ? '3' : '4',
    cookieBtnCron: orderDetails[0]?.cron_cookie_btn_enable ? orderDetails[0]?.cron_cookie_btn_enable : "",
    cornStatus: orderDetails[0]?.cron_enable_status ? orderDetails[0]?.cron_enable_status : null,
  };

  const buttonSchema = yup.object({
    buttonSelection: yup.string().required('Please select at least one option'),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: buttonSchema,
    onSubmit: (values) => {
      const data = {
        buttonValue: values?.buttonSelection,
        cookieBtnCron: values?.cookieBtnCron,
        cornStatus: values?.cornStatus,
        id: orderDetails[0]?.id,
      };

      dispatch(updateSiteButton(data))
        .unwrap()
        .then((res) => {
          if (res.status === 0) {
            toast.error(`${res?.message}`, {
              className: "toast-message",
            });
          } else {
            toast.success(`${res?.message}`, {
              className: "toast-message",
            });
          }
          setShowButton(false);
          dispatch(getAllDomainList(id));
        });
    },
  });

  return (
    <TransactionContainer>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <img
            src={orderDetails[0]?.button1ImageUrl}
            alt="Button1"
            className="inline-image"
          />
          <img
            src={orderDetails[0]?.button2ImageUrl}
            alt="Button2"
            className="inline-image"
          />
          <img
            src={orderDetails[0]?.button3ImageUrl}
            alt="Button3"
            className="inline-image"
          />
        </div>
        <div className="radio-buttons">
          <label className="image-button1 pe-3">
            <input
              type="radio"
              name="buttonSelection"
              value="1"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.buttonSelection === '1'}
            />
            Button1
          </label>
          <label className="image-button2 pe-3">
            <input
              type="radio"
              name="buttonSelection"
              value="2"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.buttonSelection === '2'}
            />
            Button2
          </label>
          <label className="image-button3 pe-3">
            <input
              type="radio"
              name="buttonSelection"
              value="3"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.buttonSelection === '3'}
            />
            Button3
          </label>
          <label className="image-none">
            <input
              type="radio"
              name="buttonSelection"
              value="4"
              onChange={handleChange}
              onBlur={handleBlur}
              checked={values.buttonSelection === '4'}
            />
            None
          </label>
          {touched.buttonSelection && errors.buttonSelection ? (
            <div className="error-message">{errors.buttonSelection}</div>
          ) : null}
        </div>

        {/* for cron enable for this domain */}
        <div className="row">

          <div className="pt-3 pb-5 col-md-6">
            <label className="image-button3 pe-3">
              <input
                type="checkbox"
                name="cornStatus"
                value="1"
                // onChange={handleChange}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked ? 1 : 0, // Set 1 for checked, 0 for unchecked
                    },
                  });
                }}
                onBlur={handleBlur}
                checked={values.cornStatus === 1}
                className="py-5"
              />
              &nbsp; Enable cron for this domain
            </label>
          </div>
          <div className="pt-3 pb-5 col-md-6">
            <div className="form-group">
              <select
                name="cookieBtnCron"
                className="form-control"
                autoComplete="off"
                value={values.cookieBtnCron}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="">Select Option</option>
                <option value="old_cookie">Landing site</option>
                <option value="new_cookie">Main site</option>

              </select>
            </div>
          </div>
        </div>
        {/* for cron enable for this domain end */}

        <div className="submit-button">
          <button className="btn btn-dark submit-admin-form" type="submit">
            Submit
          </button>
        </div>
      </form>
    </TransactionContainer>
  );
};

export default ButtonModal;
