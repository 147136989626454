import styled from "styled-components";

export const StyledPasswordModal = styled.div`
  

  .submit-btn {
    background-color: #000;
    color: #fff;
  }

  & .password-section{
        display: block;
        margin: 0 auto;
        padding-bottom: 6%;
  }
`;