import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  StyledContainer,
  StyledPageTitle,
  StyledOrderBlock,
} from "./style";
import ReactDataTable from "../../../Components/ReactDataTable/ReactDataTable";
import Modal from "../../../Components/Modal/Modal";
import ButtonModal from "../ButtonModal";
import { cookieStatus, getAllDomainList } from "../../../redux/orderSlice";
import { toast } from "react-toastify";
import { LeftOutlined } from '@ant-design/icons';

const SingleDomainList = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const domainListData = useSelector((state) => state?.order?.domainDetails || []);
  const [domainList, setDomainList] = useState([]);
  const [buttonData, setButtonData] = useState({})
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showButton, setShowButton] = useState(false);
  const [showCookieButton, setShowCookieButton] = useState(false);
  const [cookieData, setCookieData] = useState({});
  const navigate = useNavigate();
  if (!sessionStorage.getItem("loginUser")) {
    localStorage.removeItem("user");
    navigate("/");
  }
  useEffect(() => {
    setDomainList(domainListData);
  }, [domainListData])
  // const handleClose = () => setShowButton(false);
  const handleShow = (orderId) => {
    const result = domainList?.filter((item) => item?.id === orderId.id);
    setButtonData(result);
    setShowButton(true);
  };

  const [checkboxStates, setCheckboxStates] = useState(
    domainListData.reduce((acc, row) => {
      acc[row.id] = row.cookie_status === 1;
      return acc;
    }, {})
  );

  const changeCookieStatus = (cookie_id, val,cookie_type) => {
    console.log('id: ', cookie_id)
    console.log('val: ', val)
    const data = {
      id: cookie_id,
      val,
      cookie_type
    }
    dispatch(cookieStatus(data))
      .unwrap()
      .then((res) => {
        if (res.status === 401) {
          toast.error(`${res?.message}`, {
            className: "toast-message",
          });
          setShowCookieButton(false);

        } else {
          toast.success(`${res?.message}`, {
            className: "toast-message",
          });
          setShowCookieButton(false);

        }
        setShowButton(false);
        dispatch(getAllDomainList(id));
      });

  }



  const handleCheckboxChange = (id, val,cookie_type) => {

    // setCheckboxStates((prevState) => ({
    //   ...prevState,
    //   [id]: !prevState[id],
    // }));
    setShowCookieButton(id);
    setCookieData({
      id: id,
      val: val,
      cookie_type: cookie_type
    })
  };

  useEffect(() => {
    if (id) {

      dispatch(getAllDomainList(id))
        .unwrap()
        .then((res) => {
          if (res.status === 401) {
            toast.error(`${res?.message}`, {
              className: "toast-message",
            });
            localStorage.removeItem("user");
          }
        });
    }
    document.title = "Domain List | My Admin Panel";
  }, []);

  const tableData = domainList;

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Domain Name",
      selector: (row) => row?.domainName,
      sortable: true,
    },
    {
      name: "Button 1",
      selector: (row) => row?.button1,
      sortable: true,
    },
    {
      name: "Button 2",
      selector: (row) => row?.button2,
      sortable: true,
    },
    {
      name: "Button 3",
      selector: (row) => row?.button3,
      sortable: true,
    },
    {
      name: "Parent Site",
      selector: (row) => row?.parentSiteType,
      sortable: true
    },
    {
      name: "Action",
      width: "10rem",
      selector: (row) =>
        domainListData?.length !== 0 ? (
          <span>
            <Link
              to="#"
              onClick={() => handleShow({ id: row?.id })}
              style={{
                textDecoration: "none",
                border: "1px solid #e92a11",
                borderRadius: "5px",
                padding: " 0 5px",
                minWidth: "80px",
                display: "inline-block",
                textAlign: "center",
                height: "40px",
                lineHeight: "40px",
                color: " #e92a11",
                margin: "5px",
              }}
            >
              Change Button
            </Link>{" "}
          </span>

        ) : (
          " "
        ),
    },
    {
      name: "Cookie",
      width: "18rem",
      selector: (row) =>
        row?.cookie_status === 1 ? (
          <div className="ps-4 d-flex" style={{gap:"12px"}}>
            <div>
              <p style={{
                padding: "0",
                margin: "0",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: 700
              }}>New</p>
              <label className="switch">
                <input type="checkbox" className="reject-accept"
                  checked={row.cookie_enable === 1}
                  // checked={checkboxStates[row.id] || row.cookie_enable == 1}
                  onChange={(e) => handleCheckboxChange(row.id, e.target.checked, "old_cookie")}
                />
                <span className="slider round"></span>
              </label>
            </div>
            <div>
              <p style={{
                padding: "0",
                margin: "0",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: 700
              }}>Old</p>
              <label className="switch">
                <input type="checkbox" className="reject-accept"
                  checked={row.cookie_enable === 2}
                  // checked={checkboxStates[row.id] || row.cookie_enable == 1}
                  onChange={(e) => handleCheckboxChange(row.id, e.target.checked,"new_cookie")}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        ) : (
          ""
        )
    },
  ];

  return (
    <StyledContainer>
      <StyledPageTitle>
        <h1> <Link to="/landinglist" className="back-btn"> <LeftOutlined />Back</Link> <span className="ps-3">Domain List </span></h1>
      </StyledPageTitle>
      <StyledOrderBlock>
        <div className="table-block table-responsive">
          <ReactDataTable
            data={tableData}
            customColumns={columns}
            setPage={setPage}
            setLimit={setLimit}
            orderName="domainlist"
            setButtonData={setButtonData}
            setDomainList={setDomainList}
          />
          <Modal
            show={showButton}
            close={setShowButton}
            size={"xl"}
            modalHeadTitle={"Choose button from given options:"}
            showFooter={false}
          >
            <ButtonModal setShowButton={setShowButton} orderDetails={buttonData} id={id} />
          </Modal>

          <Modal
            show={showCookieButton}
            close={setShowCookieButton}
            size={"xs"}
            showFooter={false}
            children={
              <div>
                <p>Are you sure want to change the status of cookie button.?</p>
                <button
                  className="btn btn-secondary m-1 float-end"
                  onClick={() => {
                    setShowCookieButton(false);
                    setCheckboxStates((prevState) => ({
                      ...prevState,
                      [id]: prevState[id],
                    }));
                  }
                  }
                >
                  No
                </button>
                <button
                  className="btn btn-danger m-1 float-end"
                  onClick={(element) => {
                    // console.log("cookieData?.val", cookieData?.val)
                    changeCookieStatus(cookieData?.id, cookieData?.val, cookieData?.cookie_type);
                  }}
                >
                  Yes
                </button>

              </div>
            }
          >
          </Modal>
        </div>
      </StyledOrderBlock>
    </StyledContainer>
  );
};

export default SingleDomainList;


