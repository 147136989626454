import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrderService from "../services/OrderService";

export const getUsFolderCount = createAsyncThunk(
  "/order/getUsFolderCount",
  async () => {
    try {
      const response = await OrderService.getUsFolderCount();
      const data = await response.data;
      const usCount = {
        count: data?.data?.newOrdersCount ? data?.data?.newOrdersCount : 0,
        priorityOrdersCount: data?.data?.priorityOrdersCount
          ? data?.data?.priorityOrdersCount
          : 0,
        domain: "USA passport Site",
        email: "inquiries@uspassport.online",
        loginUrl: "https://admin-us.online",
      };
      return usCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getSgFolderCount = createAsyncThunk(
  "/order/getSgFolderCount",
  async () => {
    try {
      const response = await OrderService.getSgFolderCount();
      const data = await response.data;
      const sgCount = {
        count: data?.result?.newOrdersCount ? data?.result?.newOrdersCount : 0,
        priorityOrdersCount: data?.result?.priorityOrdersCount
          ? data?.result?.priorityOrdersCount
          : 0,
        domain: "Singapore Site",
        email: "inquiries@sg-arrivalcard.online",
        loginUrl: "https://sg-arrivalcard.online/admin/",
      };
      return sgCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getVnFolderCount = createAsyncThunk(
  "/order/getVnFolderCount",
  async () => {
    try {
      const response = await OrderService.getVnFolderCount();
      const data = await response.data;
      const vnCount = {
        count: data?.result?.newOrdersCount ? data?.result?.newOrdersCount : 0,
        priorityOrdersCount: data?.result?.priorityOrdersCount
          ? data?.result?.priorityOrdersCount
          : 0,
        domain: "Vietnam Site",
        email: "inquiries@vietnamportal.online",
        loginUrl: "https://admin-vn.online",
      };
      return vnCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getEvwFolderCount = createAsyncThunk(
  "/order/getEvwFolderCount",
  async () => {
    try {
      const response = await OrderService.getEvwFolderCount();
      const data = await response.data;
      const evwCount = {
        count: data?.result?.newOrdersCount ? data?.result?.newOrdersCount : 0,
        priorityOrdersCount: data?.result?.priorityOrdersCount
          ? data?.result?.priorityOrdersCount
          : 0,
        domain: "EVW Site",
        email: "inquiries@evw-uk.com",
        loginUrl: "https://admin-evw.online",
      };
      return evwCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getTurkeyFolderCount = createAsyncThunk(
  "/order/getTurkeyFolderCount",
  async () => {
    try {
      const response = await OrderService.getTurkeyFolderCount();
      const data = await response.data;
      const turkeyCount = {
        count: data?.result?.newOrdersCount ? data?.result?.newOrdersCount : 0,
        priorityOrdersCount: data?.result?.priorityOrdersCount
          ? data?.result?.priorityOrdersCount
          : 0,
        domain: "evisa-turkey.online",
        email: "inquiries@evisa-turkey.online",
        loginUrl: "https://evisa-turkey.online/admin/",
      };
      return turkeyCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getCaFolderCount = createAsyncThunk(
  "/order/getCaFolderCount",
  async () => {
    try {
      const response = await OrderService.getCaFolderCount();
      const data = await response.data;
      const caCount = {
        count: data?.result?.newOrdersCount ? data?.result?.newOrdersCount : 0,
        priorityOrdersCount: data?.result?.priorityOrdersCount
          ? data?.result?.priorityOrdersCount
          : 0,
        domain: "Canada Site",
        email: "inquiries@canada-eta.online",
        loginUrl: "https://admin-ca.online",
      };
      return caCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getEvwUnreadMailCount = createAsyncThunk(
  "/order/getEvwUnreadMailCount",
  async () => {
    try {
      const response = await OrderService.getEvwUnreadMailCount();
      const data = await response.data.data;
      const mailCount = {
        mailCount: data,
        domain: "EVW Site",
      };
      return mailCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getVnUnreadMailCount = createAsyncThunk(
  "/order/getVnUnreadMailCount",
  async () => {
    try {
      const response = await OrderService.getVnUnreadMailCount();
      const data = await response.data.data;
      const mailCount = {
        mailCount: data,
        domain: "Vietnam Site",
      };
      return mailCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getUsUnreadMailCount = createAsyncThunk(
  "/order/getUsUnreadMailCount",
  async () => {
    try {
      const response = await OrderService.getUsUnreadMailCount();
      const data = await response.data.data;
      const mailCount = {
        mailCount: data,
        domain: "USA passport Site",
      };
      return mailCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPhUnreadMailCount = createAsyncThunk(
  "/order/getPhUnreadMailCount",
  async () => {
    try {
      const response = await OrderService.getPhUnreadMailCount();
      const data = await response.data.data;
      const mailCount = {
        mailCount: data,
        domain: "Philippines Site",
      };
      return mailCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getSgUnreadMailCount = createAsyncThunk(
  "/order/getSgUnreadMailCount",
  async () => {
    try {
      const response = await OrderService.getSgUnreadMailCount();
      const data = await response.data.data;
      const mailCount = {
        mailCount: data,
        domain: "Singapore Site",
      };
      return mailCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getCaUnreadMailCount = createAsyncThunk(
  "/order/getCaUnreadMailCount",
  async () => {
    try {
      const response = await OrderService.getCaUnreadMailCount();
      const data = await response.data.data;
      const mailCount = {
        mailCount: data,
        domain: "Canada Site",
      };
      return mailCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getEgFolderCount = createAsyncThunk(
  "/order/getEgFolderCount",
  async () => {
    try {
      const response = await OrderService.getEgFolderCount();
      const data = await response.data;
      const evwCount = {
        count: data?.result?.newOrdersCount ? data?.result?.newOrdersCount : 0,
        priorityOrdersCount: data?.result?.priorityOrdersCount
          ? data?.result?.priorityOrdersCount
          : 0,
        domain: "Egypt Site",
        email: "inquiries@egypt-portal.site",
        loginUrl: "https://admin-eg.online",
        mailCount: 0,
      };
      return evwCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getKrFolderCount = createAsyncThunk(
  "/order/getKrFolderCount",
  async () => {
    try {
      const response = await OrderService.getKrFolderCount();
      const data = await response.data.data;
      const evwCount = {
        count: data?.newOrdersCount ? data?.newOrdersCount : 0,
        priorityOrdersCount: data?.priorityOrdersCount
          ? data?.priorityOrdersCount
          : 0,
        domain: "korea Site",
        email: "inquiries@korea-portal.online",
        loginUrl: "https://admin-kr.online",
        mailCount: 0,
      };
      return evwCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getPhFolderCount = createAsyncThunk(
  "/order/getPhFolderCount",
  async () => {
    try {
      const response = await OrderService.getPhFolderCount();
      const data = await response.data.data;
      const phCount = {
        count: data?.newOrdersCount ? data?.newOrdersCount : 0,
        priorityOrdersCount: data?.priorityOrdersCount
          ? data?.priorityOrdersCount
          : 0,
        domain: "Philippines Site",
        email: "inquiries@philippines-etd.com",
        loginUrl: "https://admin-ph.online",
        mailCount: 0,
      };
      return phCount;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllDomainList = createAsyncThunk(
  "/order/getAllDomainList",
  async (id) => {
    try {
      const response = await OrderService.getAllDomainList(id);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllLandingList = createAsyncThunk(
  "/order/getAllLandingList",
  async () => {
    try {
      const response = await OrderService.getAllLandingList();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getTeamMemberList = createAsyncThunk(
  "/order/getTeamMemberList",
  async () => {
    try {
      const response = await OrderService.getTeamMemberList();
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getTeamMemberById = createAsyncThunk(
  "/order/getTeamMemberById",
  async (teamMemberId) => {
    try {
      const response = await OrderService.getTeamMemberById(teamMemberId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateSiteButton = createAsyncThunk(
  "/order/updateSiteButton",
  async (buttonData) => {
    try {
      const response = await OrderService.updateSiteButton(buttonData);
      const data = await response.data;
      return data;
    } catch (error) {
      console.log(error.message);
      return error.response.data;
    }
  }
);

export const cookieStatus = createAsyncThunk(
  "/order/cookieStatus",
  async (buttonData) => {
    try {
      const response = await OrderService.cookieStatus(buttonData);
      const data = await response.data;
      return data;
    } catch (error) {
      console.log(error.message);
      return error.response.data;
    }
  }
);

export const verificationDomain = createAsyncThunk(
  "/order/verificationDomain",
  async (verificationdata) => {
    try {
      const response = await OrderService.verificationDomain(verificationdata);
      const data = await response.data;
      console.log("selected site button ", data);
      return data;
    } catch (error) {
      console.log(error.message);
      return error.response.data;
    }
  }
);

export const updateNewPassword = createAsyncThunk(
  "/order/updateNewPassword",
  async (newPasswordData) => {
    try {
      const response = await OrderService.updateNewPassword(newPasswordData);
      const data = await response.data;
      return data;
    } catch (error) {
      console.log(error.message);
      return error.response.data;
    }
  }
);

export const addTeamMember = createAsyncThunk(
  "/order/addTeamMember",
  async (teamMemberId) => {
    try {
      const response = await OrderService.addTeamMember(teamMemberId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateTeamMember = createAsyncThunk(
  "/order/updateTeamMember",
  async (teamMemberId) => {
    try {
      const response = await OrderService.updateTeamMember(teamMemberId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteTeamMember = createAsyncThunk(
  "/order/delete-team-member",
  async (data) => {
    try {
      await OrderService.deleteTeamMember(data);
      return data.teamMemberId;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    domainLoading: false,
    error: null,
    selectedData: [],
    NewOrderscount: [],
    domainDetails: [],
    LandingDetails: [],
    teamMemberList: [],
    teamMember: [],
  },
  reducers: {
    handleChange: (state, action) => {
      const allDeleteData = action.payload.selectedRows;
      const data = allDeleteData.map(({ id, status: status }) => ({
        id,
        status,
      }));
      return {
        ...state,
        selectedData: data,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUsFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.NewOrderscount = [...state.NewOrderscount, action?.payload];
    });
    builder.addCase(getUsFolderCount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getSgFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSgFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.NewOrderscount = [...state.NewOrderscount, action?.payload];
    });
    builder.addCase(getSgFolderCount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getVnFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVnFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.NewOrderscount = [...state.NewOrderscount, action?.payload];
    });
    builder.addCase(getVnFolderCount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getEvwFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEvwFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.NewOrderscount = [...state.NewOrderscount, action?.payload];
    });
    builder.addCase(getEvwFolderCount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getTurkeyFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTurkeyFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      // state.NewOrderscount = [...state.NewOrderscount, action?.payload];
    });
    builder.addCase(getTurkeyFolderCount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getCaFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCaFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.NewOrderscount = [...state.NewOrderscount, action?.payload];
    });
    builder.addCase(getCaFolderCount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getUsUnreadMailCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsUnreadMailCount.fulfilled, (state, action) => {
      state.loading = false;
      const updateNewOrdersCount = state.NewOrderscount.findIndex(
        (item) => item.domain === action?.payload?.domain
      );
      state.NewOrderscount[updateNewOrdersCount].mailCount = action?.payload
        ?.mailCount
        ? action?.payload?.mailCount
        : 0;
    });

    builder.addCase(getPhUnreadMailCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPhUnreadMailCount.fulfilled, (state, action) => {
      state.loading = false;
      const updateNewOrdersCount = state.NewOrderscount.findIndex(
        (item) => item.domain === action?.payload?.domain
      );
      state.NewOrderscount[updateNewOrdersCount].mailCount = action?.payload
        ?.mailCount
        ? action?.payload?.mailCount
        : 0;
    });
    builder.addCase(getPhUnreadMailCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getSgUnreadMailCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSgUnreadMailCount.fulfilled, (state, action) => {
      state.loading = false;
      const updateNewOrdersCount = state.NewOrderscount.findIndex(
        (item) => item.domain === action?.payload?.domain
      );
      if (action?.payload?.mailCount) {
        state.NewOrderscount[updateNewOrdersCount].mailCount = action?.payload
          ?.mailCount
          ? action?.payload?.mailCount
          : 0;
      }
    });
    builder.addCase(getSgUnreadMailCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getVnUnreadMailCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVnUnreadMailCount.fulfilled, (state, action) => {
      state.loading = false;
      const updateNewOrdersCount = state.NewOrderscount.findIndex(
        (item) => item.domain === action?.payload?.domain
      );
      state.NewOrderscount[updateNewOrdersCount].mailCount = action?.payload
        ?.mailCount
        ? action?.payload?.mailCount
        : 0;
    });
    builder.addCase(getVnUnreadMailCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getEvwUnreadMailCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEvwUnreadMailCount.fulfilled, (state, action) => {
      state.loading = false;
      const updateNewOrdersCount = state.NewOrderscount.findIndex(
        (item) => item.domain === action?.payload?.domain
      );
      state.NewOrderscount[updateNewOrdersCount].mailCount = action?.payload
        ?.mailCount
        ? action?.payload?.mailCount
        : 0;
    });
    builder.addCase(getEvwUnreadMailCount.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getCaUnreadMailCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCaUnreadMailCount.fulfilled, (state, action) => {
      state.loading = false;
      const updateNewOrdersCount = state.NewOrderscount.findIndex(
        (item) => item.domain === action?.payload?.domain
      );
      state.NewOrderscount[updateNewOrdersCount].mailCount = action?.payload
        ?.mailCount
        ? action?.payload?.mailCount
        : 0;
    });
    builder.addCase(getCaUnreadMailCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getEgFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEgFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.NewOrderscount = [...state.NewOrderscount, action?.payload];
    });
    builder.addCase(getEgFolderCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getKrFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getKrFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.NewOrderscount = [...state.NewOrderscount, action?.payload];
    });
    builder.addCase(getKrFolderCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getPhFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPhFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.NewOrderscount = [...state.NewOrderscount, action?.payload];
    });
    builder.addCase(getPhFolderCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getAllDomainList.pending, (state) => {
      state.domainLoading = true;
    });
    builder.addCase(getAllDomainList.fulfilled, (state, action) => {
      state.domainLoading = false;
      state.domainDetails = action?.payload?.data;
    });
    builder.addCase(getAllDomainList.rejected, (state, action) => {
      state.domainLoading = false;
    });
    builder.addCase(getAllLandingList.pending, (state) => {
      state.domainLoading = true;
    });
    builder.addCase(getAllLandingList.fulfilled, (state, action) => {
      state.domainLoading = false;
      state.LandingDetails = action?.payload?.data;
    });
    builder.addCase(getAllLandingList.rejected, (state, action) => {
      state.domainLoading = false;
    });
    builder.addCase(updateSiteButton.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSiteButton.fulfilled, (state, action) => {
      state.loading = false;
      state.domainDetails = action?.payload?.data;
    });
    builder.addCase(updateSiteButton.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(verificationDomain.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verificationDomain.fulfilled, (state, action) => {
      state.loading = false;
      // state.domainDetails = action?.payload?.data;
    });
    builder.addCase(verificationDomain.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateNewPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateNewPassword.fulfilled, (state, action) => {
      state.loading = false;
      // state.domainDetails = action?.payload?.data;
    });
    builder.addCase(updateNewPassword.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getTeamMemberList.pending, (state) => {
      state.domainLoading = true;
    });
    builder.addCase(getTeamMemberList.fulfilled, (state, action) => {
      state.domainLoading = false;
      state.teamMemberList = action?.payload?.data;
    });
    builder.addCase(getTeamMemberList.rejected, (state, action) => {
      state.domainLoading = false;
    });

    builder.addCase(getTeamMemberById.pending, (state) => {
      state.domainLoading = true;
    });
    builder.addCase(getTeamMemberById.fulfilled, (state, action) => {
      state.domainLoading = false;
      state.teamMember = action?.payload?.data;
    });
    builder.addCase(getTeamMemberById.rejected, (state, action) => {
      state.domainLoading = false;
    });

    builder.addCase(deleteTeamMember.pending, (state) => {
      state.domainLoading = true;
    });
    builder.addCase(deleteTeamMember.fulfilled, (state, action) => {
      state.domainLoading = false;
      state.teamMemberList = state.teamMemberList.filter(
        (item) => item.id !== action.payload
      );
    });
    builder.addCase(deleteTeamMember.rejected, (state, action) => {
      state.domainLoading = false;
    });
  },
});

export const {
  toggleSidebar,
  handleChange,
  handlePerRowsChange,
  handlePageChange,
} = orderSlice.actions;

export default orderSlice.reducer;
