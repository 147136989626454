import styled from "styled-components";

export const StyledLogin = styled.div`
  

  .submit-btn {
    background-color: #000;
    color: #fff;
  }
`;
